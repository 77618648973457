<template>
  <div class="live-page">
    <div v-if="!loading" class="content-wrap">
      <div class="type-blank">
        <div class="left">直播类型：</div>
        <div class="type-list" :class="{'type-list-all' : isOpen}">
          <div class="list-item" :class="{'list-item-selected' : categoryId === ''}" @click="checkedCategory('')">全部</div>
          <div class="list-item" :class="{'list-item-selected' : categoryId === val.id}" v-for="val in categoryList" :key="val.id" @click="checkedCategory(val.id)">{{ val.name }}</div>
        </div>
        <div v-if="typeHeight > 28" class="down-blank" @click="openType">
          <span>{{ isOpen ? '收起' : '展开' }}</span>
          <img :src="isOpen ? require('../assets/img/live_up_icon.png') : require('../assets/img/live_down_icon.png')" />
        </div>
      </div>
      <div class="live-blank">
        <div v-if="liveList.length > 0" class="list-wrap">
          <div class="live-item" v-for="(item,index) in liveList" :key="index" @click="toNavUrl(item)">
            <div class="title-wrap">
              <span v-if="item.is_free === 1" class="free-state">免费</span>
              <span v-if="item.is_free === 2" class="free-state" style="background-color: #7438ef;">密码</span>
              <span class="live-title">{{ item.name }}</span>
            </div>
            <span class="live-date">直播时间：{{ item.start_time | getYMDHMS('yyyy-MM-dd hh:mm') }}</span>
            <div class="live-info">
              <img v-if="item.teachers && item.teachers[0] && item.teachers[0].avatar" class="live-teacher" :src="item.teachers[0].avatar" />
              <img v-else class="live-teacher" src="../assets/img/avtar_icon.jpg" />
              <!-- 0=待直播，1=直播中 3=已结束 5=回放-->
              <template>
                <div v-if="item.live_status === 0" class="live-state live-state-before">
                  <img src="../assets/img/live_before_icon.png" />
                  <span>未开始</span>
                </div>
                <div v-if="item.live_status === 1" class="live-state live-state-start">
                  <img src="../assets/img/live_start_icon.png" />
                  <span>直播中</span>
                </div>
                <div  v-if="item.live_status === 3" class="live-state live-state-end">
                  <img src="../assets/img/live_end_icon.png" />
                  <span>已结束</span>
                </div>
              </template>
              <div class="right">
                <span v-if="item.is_free == 0" class="price">￥{{item.price}}</span>
                <span class="count">{{ item.virtually_pay_num !== '0' && item.virtually_pay_num  !== '' ? item.virtually_pay_num : item.user_count }}人已报名</span>
              </div>
            </div>
          </div>
        </div>
        <empty-data v-if="liveList.length === 0" />
        <div v-if="total > 0" class="page-wrap">
          <el-pagination
              background
              layout="prev, pager, next"
              :page-size="queryParams.limit"
              :current-page="queryParams.page"
              :total="total"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
          <div class="total-wrap">共 {{ total }} 条</div>
        </div>
      </div>
    </div>
    <loading :is-load="loading" />
  </div>
</template>

<script>
import * as courseService from '@/service/course-service';
import emptyData from '@/views/modules/empty-data';
export default {
  name: 'liveList',
  components: {
    emptyData
  },
  data() {
    return {
      loading: false,
      isOpen: false, // 是否展开
      typeHeight: 0, // 类型高度
      categoryList: [], // 直播类型
      categoryId: '', // 当前类型
      liveList: [], // 直播列表
      queryParams: {
        page: 1,
        limit: 12
      },
      total: 0
    };
  },
  mounted() {
    this.getCategoryList();
    this.getLiveList();
  },
  methods: {
    /**
     * 获取课程科目
     * */
    getCategoryList() {
      courseService.getCategoryList().then(res => {
        if (res.code === 1) {
          this.categoryList = res.data;
          this.$nextTick(() => {
            const dom = document.querySelector('.type-list');
            if (dom) {
              this.typeHeight = dom.clientHeight || 0;
            }
          });
        }
      });
    },
    checkedCategory(id) {
      this.categoryId = id;
      this.queryParams.page = 1;
      this.total = 0;
      this.getLiveList();
    },
    /**
     * 获取直播列表
     * */
    getLiveList() {
      const data = {
        course_type: 2,
        ...this.queryParams,
        is_show_home: 1,
        category_id: this.categoryId
      };
      this.loading = true;
      courseService.getCourseSearch(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.liveList = res.data || [];
          this.total = res.data.total || 0;
        }
      });
    },
    /**
     * 切换分页
     * */
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getLiveList();
    },
    openType() {
      this.isOpen = !this.isOpen;
    },
    toNavUrl(info) {
      this.$router.push({
        path: '/courseDetail',
        query: {
          id: info.id
        }
      });
    },
    /**
     * 根据时间直播状态
     * */
    formatterState(startDate, endDate) {
      const curTime = new Date().getTime();
      const startTime = new Date(startDate).getTime();
      const endTime = new Date(endDate).getTime();
      // 开始时间大于当前时间，未开始
      if (startTime > curTime) {
        return 1;
      }
      // 当前时间大于开始时间并小于结束时间，直播中
      if (curTime > startTime && new Date().toDateString() === new Date(startDate).toDateString()) {
        return 2;
      }
      // 已结束
      return 3;
    }
  }
};
</script>

<style lang="scss" scoped>
.live-page {
  .content-wrap {
    display: flex;
    flex-direction: column;
    padding: 20px 0 79px;
    .type-blank {
      display: flex;
      align-items: flex-start;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 17px 16px 17px 25px;
      .left {
        font-size: 16px;
        color: #333333;
        margin-right: 43px;
        line-height: 28px;
      }
      .type-list {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        height: 28px;
        overflow: hidden;
        .list-item {
          display: flex;
          align-items: center;
          height: 28px;
          padding: 0 9px 0 7px;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
        }
        .list-item-selected {
          background: #2869F5;
          border-radius: 2px;
          color: #FFFFFF;
        }
      }
      .type-list-all {
        height: auto;
      }
      .down-blank {
        display: flex;
        align-items: center;
        height: 28px;
        line-height: 1;
        margin-left: 17px;
        cursor: pointer;
        span {
          font-size: 14px;
          color: #2869F5;
          margin-right: 5px;
        }
        img {
          width: 11px;
          height: 6px;
        }
      }
    }
    .live-blank {
      background: #FFFFFF;
      border-radius: 8px;
      padding: 5px 20px 71px;
      margin-top: 15px;
      .list-wrap {
        display: flex;
        flex-wrap: wrap;
        .live-item {
          display: flex;
          flex-direction: column;
          margin: 20px 17.5px 0 0;
          width: 375px;
          height: 155px;
          background: #F8F9FB;
          border-radius: 6px;
          padding: 20px 17px 0 19px;
          line-height: 1;
          cursor: pointer;
          .title-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .free-state {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 40px;
              height: 22px;
              background: #FB8A00;
              border-radius: 4px;
              margin-right: 10px;
              font-size: 14px;
              color: #FFFFFF;
            }
            .live-title {
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 18px;
              color: #222222;
            }
          }
          .live-date {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            margin-bottom: 20px;
          }
          .live-info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .live-teacher {
              width: 38px;
              height: 38px;
              border-radius: 50%;
            }
            .live-state {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 25px;
              width: 80px;
              height: 24px;
              border-radius: 12px;
              img {
                margin-right: 6px;
              }
              span {
                font-size: 14px;
                color: #FFFFFF;
              }
            }
            .live-state-start {
              background: #2869F5;
              img {
                width: 14px;
                height: 15px;
              }
            }
            .live-state-before {
              background: #7438EF;
              img {
                width: 16px;
                height: 16px;
              }
            }
            .live-state-end {
              background: #CFCFCF;
              img {
                width: 16px;
                height: 16px;
              }
            }
            .right {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              min-height: 100%;
              flex: 1;
              align-items: flex-end;
              .price {
                font-size: 18px;
                font-weight: bold;
                color: #E22323;
                margin-bottom: 15px;
              }
              .count {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }
        .live-item:nth-child(3n) {
          margin-right: 0;
        }
        .live-item:hover {
          background: #FFFFFF;
          border: 1px solid #2869F5;
          box-shadow: 2px 4px 9px 0px rgba(40,105,245,0.09);
        }
      }
    }
    .page-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;

      .total-wrap {
        margin-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}

</style>
